import { useContext } from 'react'

import { useTranslation } from '@hooks/useTranslation'
import { StoreContext } from '@contexts/StoreContext'
import {
  actionNavItems,
  analysisNavItems,
  excludeNavItemsBytTitle,
  mainNavItems,
  NavTitles,
  preNavItems,
} from '@src/data/navItems'

export default function useNavItems() {
  const { t } = useTranslation()
  const { isClauseAdviserEnabled, isContractChatEnabled, isPlaybooksEnabled, loggedIn } =
    useContext(StoreContext)
  const exclude: NavTitles[] = []

  if (!isClauseAdviserEnabled || !loggedIn) exclude.push(t('nav.Clause Adviser') as NavTitles)
  if (!isContractChatEnabled || !loggedIn) exclude.push(t('nav.Contract Assistant') as NavTitles)
  if (!isPlaybooksEnabled || !loggedIn) exclude.push(t('nav.Playbooks') as NavTitles)

  return {
    preNavItems,
    mainNavItems,
    actionNavItems,
    analysisNavItems: excludeNavItemsBytTitle(analysisNavItems, ...exclude),
  }
}
